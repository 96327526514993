import React from "react"
import { useQuery } from "@apollo/react-hooks"
import { FlexboxGrid, Col, Container, Header, Content } from "rsuite"
import ReactPlayer from "react-player"
import renderHTML from "react-render-html"
import Helmet from "react-helmet"

import GET_HOME from "../queries/getHome.graphql"

import SEO from "../components/seo"
import TopHeader from "../components/topHeader"
import CustomMenu from "../components/custommenu"
import FooterSection from "../components/footer"
import { LazyImage } from "../components/LazyImage"

import "../styles/global.css"

const IndexPage = () => {
  const { loading, error, data } = useQuery(GET_HOME)
  if (loading) return null
  if (error) return `Error! ${error}`

  const {
    homepageVideo,
    homepageAPropos,
    homepageEpisodes,
  } = data.pageBy.acfHome

  const episodeData = homepageEpisodes.map(episode => {
    let data = {
      episodeTitre: episode.homepageTitreEpisode,
      episodeDescription: episode.homepageDescriptionEpisode,
      episodeNumero: episode.homepageNumeroEpisode,
      episodeImage: episode.homepageImageEpisode.mediaItemUrl,
    }
    return data
  })

  const ListEpisode = episodes => {
    return (
      <>
        {episodes.length > 3 ? (
          <>
            <FlexboxGrid justify="center" className="wrap-serie">
              {episodes.map(
                (episode, i) =>
                  i < 3 && (
                    <FlexboxGrid.Item
                      key={i}
                      componentClass={Col}
                      colspan={24}
                      md={6}
                      xs={24}
                    >
                      <div className="episode-block">
                        <a href={`/episode-${episode.episodeNumero}`}>
                          <LazyImage
                            alt="img"
                            src={
                              episode &&
                              episode.episodeImage &&
                              episode.episodeImage
                            }
                            width="100%"
                          />

                          {episode && episode.episodeTitre && (
                            <h5 className="homepage-titre-episode">
                              {episode.episodeTitre}
                            </h5>
                          )}
                          {episode && episode.episodeNumero && (
                            <span className="homepage-numero-episode">
                              ÉPISODE {episode.episodeNumero}
                            </span>
                          )}
                          <div className="homepage-description-episode">
                            {episode &&
                              episode.episodeDescription &&
                              renderHTML(episode.episodeDescription)}
                          </div>
                        </a>
                      </div>
                    </FlexboxGrid.Item>
                  )
              )}
            </FlexboxGrid>
            <FlexboxGrid justify="center" className="wrap-serie">
              {episodes.map(
                (episode, i) =>
                  i > 2 && (
                    <FlexboxGrid.Item
                      key={i}
                      componentClass={Col}
                      colspan={24}
                      md={6}
                      xs={24}
                    >
                      <div className="episode-block">
                        <a href={`/episode-${episode.episodeNumero}`}>
                          <LazyImage
                            alt="img"
                            src={
                              episode &&
                              episode.episodeImage &&
                              episode.episodeImage
                            }
                            width="100%"
                          />
                          {episode && episode.episodeTitre && (
                            <h5 className="homepage-titre-episode">
                              {episode.episodeTitre}
                            </h5>
                          )}
                          {episode && episode.episodeNumero && (
                            <span className="homepage-numero-episode">
                              ÉPISODE {episode.episodeNumero}
                            </span>
                          )}
                          <div className="homepage-description-episode">
                            {episode &&
                              episode.episodeDescription &&
                              renderHTML(episode.episodeDescription)}
                          </div>
                        </a>
                      </div>
                    </FlexboxGrid.Item>
                  )
              )}
            </FlexboxGrid>
          </>
        ) : (
          <FlexboxGrid justify="center" className="wrap-serie">
            {episodes.map((episode, i) => (
              <FlexboxGrid.Item
                key={i}
                componentClass={Col}
                colspan={24}
                md={6}
                xs={24}
              >
                <div className="episode-block">
                  <a href={`/episode-${episode.episodeNumero}`}>
                    <img
                      alt="img"
                      src={
                        episode && episode.episodeImage && episode.episodeImage
                      }
                      width="100%"
                    />
                    {episode && episode.episodeTitre && (
                      <h5 className="homepage-titre-episode">
                        {episode.episodeTitre}
                      </h5>
                    )}
                    {episode && episode.episodeNumero && (
                      <span>ÉPISODE {episode.episodeNumero}</span>
                    )}
                    {episode &&
                      episode.episodeDescription &&
                      renderHTML(episode.episodeDescription)}
                  </a>
                </div>
              </FlexboxGrid.Item>
            ))}
          </FlexboxGrid>
        )}
      </>
    )
  }

  return (
    <>
      <SEO
        title="Accueil"
        keywords={[`Les Bonnes Nouvelles: parce que nos vies comptent`]}
      />
      <Helmet>
        <title>Accueil</title>
        <meta name="description" content="Accueil" />
      </Helmet>
      <CustomMenu />
      <Container id="page-wrap">
        <Header>
          <div className="home-header">
            <TopHeader />
            <FlexboxGrid
              justify="center"
              align="middle"
              className="content-header"
            >
              <ReactPlayer
                className="react-player"
                url={homepageVideo.mediaItemUrl}
                width="100%"
                height="100%"
                controls={false}
                loop={true}
                muted={true}
                config={{ forceVideo: true, forceHLS: true }}
                playsinline={true}
                playing
              />
              <svg
                className="grand-logo"
                width="1016"
                height="464.5"
                viewBox="0 0 1016 464.5"
              >
                <defs>
                  <filter
                    id="Les"
                    x="321.5"
                    y="0"
                    width="200"
                    height="168"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dx="8" dy="8" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="7.5" result="blur" />
                    <feFlood flood-opacity="0.729" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Bonnes"
                    x="199.5"
                    y="44"
                    width="663"
                    height="271"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dx="8" dy="8" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="7.5" result="blur-2" />
                    <feFlood flood-opacity="0.729" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Nouvelles"
                    x="40.5"
                    y="178"
                    width="953"
                    height="271"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dx="8" dy="8" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="7.5" result="blur-3" />
                    <feFlood flood-opacity="0.729" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g
                  id="Groupe_49"
                  data-name="Groupe 49"
                  transform="translate(-192 -189.5)"
                >
                  <g
                    id="Groupe_30"
                    data-name="Groupe 30"
                    transform="translate(-1449.979 -328.45)"
                  >
                    <g
                      className="cls-2"
                      transform="matrix(1, 0, 0, 1, 1641.98, 517.95)"
                      filter="url(#Les)"
                    >
                      <text
                        id="Les-2"
                        data-name="Les"
                        class="cls-1"
                        transform="translate(336 111.5)"
                        fill="#fcc503"
                        font-size="109"
                      >
                        <tspan x="0" y="0">
                          Les
                        </tspan>
                      </text>
                    </g>
                    <g
                      class="cls-3"
                      transform="matrix(1, 0, 0, 1, 1641.98, 517.95)"
                      filter="url(#Bonnes)"
                    >
                      <text
                        id="Bonnes-2"
                        data-name="Bonnes"
                        class="cls-1"
                        transform="translate(214 237.5)"
                        fill="#fcc503"
                        font-size="201"
                      >
                        <tspan x="0" y="0">
                          Bonnes
                        </tspan>
                      </text>
                    </g>
                    <g
                      class="cls-4"
                      transform="matrix(1, 0, 0, 1, 1641.98, 517.95)"
                      filter="url(#Nouvelles)"
                    >
                      <text
                        id="Nouvelles-2"
                        data-name="Nouvelles"
                        class="cls-1"
                        transform="translate(55 371.5)"
                        fill="#fcc503"
                        font-size="201"
                      >
                        <tspan x="0" y="0">
                          Nouvelles
                        </tspan>
                      </text>
                    </g>
                  </g>
                  <g
                    id="Groupe_48"
                    data-name="Groupe 48"
                    transform="translate(-136 563)"
                  >
                    <text
                      id="Parce_que_nos_vies_comptent"
                      data-name="Parce que nos vies comptent"
                      transform="translate(328 32)"
                      fill="#fff"
                      font-size="35"
                      font-family="Lato-Bold, Lato"
                      font-weight="700"
                      letter-spacing="0.15em"
                    >
                      <tspan x="159.794" y="35">
                        PARCE QUE NOS VIES COMPTENT
                      </tspan>
                    </text>
                  </g>
                </g>
              </svg>
              {/* <img className="grand-logo" src={grandLogo} alt="logo" /> */}
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={24}
                xs={24}
              ></FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Header>
        <Content>
          <div className="content">
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item
                componentClass={Col}
                colspan={24}
                md={18}
                xs={24}
              >
                <h5 className="homepage-la-serie">LA SÉRIE</h5>
                <div className="homepage-texte-a-propos">
                  {homepageAPropos && renderHTML(homepageAPropos)}
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
            {ListEpisode(episodeData)}
          </div>
        </Content>
        <FooterSection />
      </Container>
    </>
  )
}

export default IndexPage
